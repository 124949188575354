import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Header from '../../../components/Header';
import { Line } from '../../../components/Line';
import EnhancedTable from '../../../components/Table';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import Titulo, { Subtitulo } from '../../../components/Titulo';
import { fetchEstats } from '../../../services/api/estatsAPI';
import { fetchFluxoAtivos } from '../../../services/api/fluxoAtivosAPI';
import { fetchFluxoCaixa, fetchIFsCaixa } from '../../../services/api/fluxoCaixaAPI';
import { fetchPerfHist } from '../../../services/api/perfHistAPI';
import { fetchPosCon } from '../../../services/api/posConAPI';
// import { fetchCartExp } from '../../../services/api/cartExpAPI';
import { sendAnalyticsData } from '../../../services/api/preferenceAPI';
import { fetchResumoCart } from '../../../services/api/resumoCartAPI';
import { FormatTypes, helperFormat } from '../../../services/helper';
import { setIF } from '../../../services/reducers/fluxoCaixaSlice';
import { setData } from '../../../services/reducers/preferenceSlice';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingModal from '../LoadingModal';
import FixedLoadingButton from '../../../components/FixedLoadingButton';
import Instituicao from '../../../components/Instituicao';

interface Props {
  ignoraMesAtual?: boolean | null
  dispatchPDF?: boolean
}

export default function FluxoCaixa({ ignoraMesAtual, dispatchPDF }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uselocation = useLocation();

  const [
    obj_datas,
    fluxo_de_ativos_caixa,
    isLoaded,
    loading,
    isLoadedLista,
    loadingLista,
    listaIFs,
    IF,
    loadedCarteiraList,
    isLoadingMinimized,
    params,
  ] = useAppSelector((state) => [
    state.fluxoCaixa.obj_datas,
    state.fluxoCaixa.fluxo_de_ativos_caixa,
    state.fluxoCaixa.isLoaded,
    state.fluxoCaixa.loading,
    state.fluxoCaixa.isLoadedLista,
    state.fluxoCaixa.loadingLista,
    state.fluxoCaixa.listaIFs,
    state.fluxoCaixa.IF,
    state.preference.loadedCarteiraList,
    state.loadMinimized.isLoadingMinimized,
    state.preference.params,
  ]);

  useEffect(() => {
    if (!dispatchPDF) document.title = `${process.env.REACT_APP_TABNAME} - Fluxo de Caixa`;
  }, []);

  useEffect(() => {
    if (isLoaded && !dispatchPDF) dispatch(sendAnalyticsData(location.href));
  }, [params, isLoaded]);


  useEffect(() => {
    async function loadDados() {
      await dispatch(fetchIFsCaixa());
    }
    if (!isLoadedLista && !loadingLista && !dispatchPDF) {
      loadDados();

      dispatch(fetchResumoCart());
      dispatch(fetchPerfHist());
      dispatch(fetchPosCon());
      // dispatch(fetchCartExp());
      dispatch(fetchFluxoAtivos('mes_atual'));
      dispatch(fetchEstats());
    }
  }, [isLoadedLista]);

  useEffect(() => {
    if (!loading && fluxo_de_ativos_caixa.length === 0 && IF !== '') {
      dispatch(fetchFluxoCaixa());
    }
  }, [isLoaded, IF]);

  /**
   * Carregar o IF quando a p�gina for recarregada
   */
  useEffect(() => {
    if(isLoadedLista && IF === ''){ // se n�o tiver o isLoadedLista, o componente select da warning out-of-range
      const IF_history = uselocation?.state?.IF ?? '';
      if(IF_history){
        dispatch(setIF(IF_history));
      }
    }
  }, [isLoadedLista]);

  const handleSelect = (value: string) => {
    const url = `?carteira=${params.carteira}&periodo=${params.periodo}`
    navigate(url, { state: { IF: value } });  // adiciona o IF selecionado no state do History router
    dispatch(setIF(value));
  };

  const rowsFluxoCaixa =
    isLoaded && fluxo_de_ativos_caixa
      ? fluxo_de_ativos_caixa.slice(1).map((row) => {
        let i = 0;
        const dataAux = row[i++] as string;
        return {
          data: dataAux.split('-').reverse().join('/'),
          op: row[i++],
          ativo: row[i++],
          quant: helperFormat(row[i++], FormatTypes.decimals, 2),
          preco: helperFormat(row[i++], FormatTypes.decimals, 2),
          valor: helperFormat(row[i++], FormatTypes.decimals, 2),
          saldo: helperFormat(row[i++], FormatTypes.decimals, 2),
        };
      })
      : null;

  const columnsFluxoCaixa: ITableColumns[] = [
    {
      id: 'data',
      label: 'Data',
      freeze: true,
      align: AlignTypes.left,
    },
    {
      id: 'op',
      label: 'Operação',
      align: AlignTypes.left,
    },
    {
      id: 'ativo',
      label: 'Ativo',
      align: AlignTypes.left,
    },
    {
      id: 'quant',
      label: 'Quantidade',
      align: AlignTypes.right,
    },
    {
      id: 'preco',
      label: 'Preço do Ativo',
      align: AlignTypes.right,
    },
    {
      id: 'valor',
      label: 'Valor Bruto',
      align: AlignTypes.right,
    },
    {
      id: 'saldo',
      label: 'Saldo Bruto',
      align: AlignTypes.right,
    },
  ];

  return (
    <>
      <Grid>
        <Header
          title="Fluxo de Caixa"
          containerAux
          instituicao
          periodo
          periodoProps={{
            options: listaIFs,
            initialOption: IF,
            loadingOptions: loadingLista,
            handleSelect,
          }}
          obj_datas={obj_datas}
        />
        <Line />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>

          {IF !== '' ? (
            isLoaded ? (
              isLoaded && fluxo_de_ativos_caixa && fluxo_de_ativos_caixa.length > 1 ? (
                <>
                  <Subtitulo>Movimentações</Subtitulo>
                  <EnhancedTable rows={rowsFluxoCaixa} columns={columnsFluxoCaixa} height={500} />
                </>
              ) : (
                !loading ? <span>Nenhuma movimentação encontrada neste período</span> : null
              )
            ) : null
          ) : null}

          {isLoadedLista && listaIFs.length === 0 && IF === '' ? (
            <Subtitulo>
              Nenhuma instituição financeira foi encontrada nesta carteira, entre em contato com seu
              gestor{' '}
            </Subtitulo>
          ) : null}

          {isLoadedLista && listaIFs.length !== 0 && IF === '' ? (<div>
            <Titulo>
              Selecione uma instituição
            </Titulo>
            <Instituicao
              options={listaIFs}
              initialOption={IF}
              loadingOptions={loadingLista}
              handleSelect={handleSelect}
              floatLeft={true}
            />
          </div>
          ) : null}

        </Grid>
      </Grid>
      <LoadingModal loading={(((IF !== '' && !isLoaded) || (!loadedCarteiraList || !isLoadedLista || loading)) && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton loading={((IF !== '' && !isLoaded) || (!loadedCarteiraList || !isLoadedLista || loading)) ?? false} isLoadingMinimized={isLoadingMinimized} />
    </>
  );
}
